@import "base/helpers";

@include component(user-listing) {
  @include typography-v2-base;

  display: flex;
  font-weight: bold;
  align-items: center;

  @include spacing-margin-top-regular;

  @include breakpoint(phablet) {
    margin-top: 0;

    @include spacing-margin-xsmall;
  }

  @include part(info) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-right: 10px;

    p {
      margin: 0;
      padding-left: 10px;

      @include typography-v2-size-4;
    }

    &-additional {
      display: flex;
      flex-direction: row;

      @include breakpoint(phablet) {
        align-items: flex-end;
      }
    }
  }

  @include part(amount) {
    @include typography-v2-size-4;
  }

  @include part(info-additional) {
    p {
      font-size: 1.2rem;
      padding-top: 0.35rem;
      color: gray;
      font-weight: 400;

      &:before {
        content: " - ";
        padding-right: 3px;
      }
    }
  }

  @include part(image) {
    .avatar {
      margin-top: 0;
    }
  }

  @include part(contact-icon) {
    svg {
      width: 45px;
      height: 45px;
      padding-right: 5px;

      path {
        &:hover {
          fill: #1fa8df;
        }
      }
    }
  }
}
