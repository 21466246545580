@import "base/helpers";

@include component(comment-form) {
  // Variables ----------------------
  $avatar-size-small: 36px !default;
  $avatar-size-small-desktop: 56px !default;
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 30%) !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;
  $inputs_borderWidth: 1px !default;

  // Parts ----------------------
  @include part(comment) {
    align-items: top;
    display: flex;
    width: 100%;
  }

  @include part(post-actions) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint(phablet) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }

    @include spacing-margin-top-xsmall;
    @include spacing-margin-bottom-xxlarge;

    .Checkbox {
      margin: 0 0 6px;
      padding: 0;

      @include breakpoint(phablet) {
        margin: 0;
      }
    }

    button {
      padding: 0.6rem 2.5rem;
    }
  }

  @include part(comment-body) {
    flex: 1 1 auto;
    margin-left: 8px;
  }

  @include part(action) {
    text-align: right;
  }

  @include part(error) {
    color: $clr_error;
    margin-top: -30px;
    margin-bottom: 20px;
    text-align: right;
  }

  @include part(textarea) {
    @include typography-v2-base;

    border: $inputs_borderWidth solid $inputs_borderColor;
    border-radius: 16px;
    display: block;
    line-height: 1.29;
    padding: 8px 16px;
    width: 100%;

    @include typography-v2-size-4;

    &:focus {
      border-color: $clr_brandPrimary;
      outline: 0;
    }

    @include breakpoint(phablet) {
      border-radius: 32px;
      padding: 16px 24px;
    }
  }

  .Checkbox.--label-medium span {
    font-weight: normal;
    font-size: 1.5rem;
  }

  // Options ----------------------
  @include option(reply-mode) {
    margin-bottom: 16px;
    margin-left: calc(8px + #{$avatar-size-small});
    margin-top: 16px;

    @include breakpoint(phablet) {
      margin-bottom: 24px;
      margin-left: calc(8px + #{$avatar-size-small-desktop});
      margin-top: 24px;
    }
  }

  @include option(in-thread) {
    margin-left: 0;
  }

  @include option(v2) {
    @include part(textarea) {
      padding: 6px 14px !important;

      @include breakpoint(phablet) {
        padding: 10px 20px !important;
      }
    }

    @include part(comment-body) {
      span {
        @include typography-v2-size-5;

        font-weight: normal;
      }
    }
  }
}
