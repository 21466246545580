@use "sass:math";
@import "base/helpers";

@include component(timeline-story) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_brandSecondary: #e444a0 !default;
  $clr_brandTertiary: #ffb202 !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $timing_fast: 0.2s !default;
  $timeline-story-icon-desktop: 3rem;

  // Component ----------------------
  font-family: $font-family-primary;
  position: relative;
  text-align: center;

  // Parts ----------------------
  @include part(inner) {
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    max-width: 21rem + $timeline-story-icon-desktop*0.5 + 1.5rem; // Max width + half icon size + spacing

    @include breakpoint(tablet) {
      .timeline-story.--align-left & {
        margin-right: 0;
      }

      .timeline-story.--align-right & {
        margin-left: 0;
      }
    }
  }

  @include part(icon) {
    align-items: center;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 2rem;
    height: 2.75rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 2.75rem;

    @include breakpoint(tablet) {
      font-size: 2.25rem;
      height: 3rem;
      position: absolute;
      width: 3rem;

      .timeline-story.--align-left & {
        right: math.div($timeline-story-icon-desktop, -2);
      }

      .timeline-story.--align-right & {
        left: math.div($timeline-story-icon-desktop, -2);
      }
    }

    .timeline-story.--build & {
      background-color: $clr_brandPrimary;
    }

    .timeline-story.--community & {
      background-color: $clr_brandSecondary;
    }

    .timeline-story.--launch & {
      background-color: $clr_brandTertiary;
    }
  }

  @include part(date) {
    @include typography-bold;

    border-bottom: 1px solid;
    display: inline-block;
    font-size: 0.875rem;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    padding-bottom: 0.25rem;

    @include breakpoint(tablet) {
      margin-top: 0.25rem;
    }

    .timeline-story.--build & {
      border-bottom-color: $clr_brandPrimary;
    }

    .timeline-story.--community & {
      border-bottom-color: $clr_brandSecondary;
    }

    .timeline-story.--launch & {
      border-bottom-color: $clr_brandTertiary;
    }
  }

  @include part(heading) {
    @include typography-bold;

    font-size: 1.5rem;
    line-height: 1.2;
  }

  @include part(content) {
    font-size: 1rem;

    // Scoped styles (to target HTML elements)
    a {
      color: currentcolor;
      text-decoration: none;
      border-bottom: 1px solid currentcolor;
      transition: border-color $timing_fast ease-in-out;

      &:hover {
        border-bottom-color: $clr_brandPrimary;
      }
    }

    p {
      margin-top: 0;
    }
  }

  @include part(action) {
    margin-top: 1rem;
  }

  @include part(image) {
    border-radius: 4px;
    display: block;
    margin-top: 1rem;
    width: 100%;
  }

  // Options - align ----------------------
  @include option(align-left) {
    @include breakpoint(tablet) {
      margin-right: 0;
      padding-right: $timeline-story-icon-desktop*0.5 + 1.5rem; // Half icon size + spacing
      text-align: right;
      transform: translateX(-50%);
    }
  }

  @include option(align-right) {
    @include breakpoint(tablet) {
      padding-left: $timeline-story-icon-desktop*0.5 + 1.5rem; // Half icon size + spacing
      text-align: left;
      transform: translateX(50%);
    }
  }
}
