@import "base/helpers";

@include component(comments) {
  // Variables ----------------------
  $clr_text: #333 !default;
  $clr_textSubdued: lighten($clr_text, 30%) !default;
  $inputs_borderColor: lighten($clr_textSubdued, 30%) !default;

  // Component ----------------------
  // Override legacy styles
  float: left;
  width: 100%;

  // Parts ----------------------
  @include part(form) {
    border-bottom: 1px solid $inputs_borderColor;

    @include spacing-margin-top-xxlarge;

    @include option(v2) {
      margin-bottom: 0;
      border-bottom: none;
    }
  }

  @include option(v2) {
    border-top: 1px solid #eee;

    h2 {
      @include spacing-margin-top-regular;
      @include spacing-margin-bottom-xxlarge;
    }
  }

  @include part(amount) {
    font-weight: 400;
  }
}
