@use "sass:math";
@import "base/helpers";

@include component(campaign-hero) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $campaign-hero-icon-height: 20px;
  $campaign-hero-icon-height-desktop: 40px;

  // Component ----------------------
  border-radius: 4px;

  @include part(v2) {
    border-radius: 0;

    @include breakpoint(phablet) {
      border-radius: 10px;
      margin: 0 1rem 1rem;

      @include part(container) {
        border-radius: 10px;
      }

      @include part(image) {
        border-radius: 10px;
      }
    }

    @include part(container) {
      border-radius: 0;
    }

    .campaign-hero__image {
      border-radius: 0;

      @include breakpoint(phablet) {
        border-radius: 10px;
      }
    }

    @include option(campaign-carousel) {
      margin: 0;
      border-radius: 10px;
    }
  }

  // Parts ----------------------
  @include part(container) {
    width: 100%;
    border-radius: 4px;
  }

  // This is what's responsible for displaying the image
  @include part(image) {
    background-position: center;
    background-size: cover;
    background-color: $clr_lightGrey;
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0;
    padding-bottom: 56.25%;
    position: relative;
    border-radius: 4px;
  }

  // This is for alt text and if users try to right-click and save the image
  @include part(image-tag) {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: 0%;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @include part(video) {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0;
    padding-bottom: 56.25%;
    position: relative;
  }

  @include part(video-tag) {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  @include part(play) {
    background-color: transparentize($clr_brandPrimary, 0.3);
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    height: $campaign-hero-icon-height * 1.75;
    left: 50%;
    padding: 0;
    position: absolute;
    text-indent: -9999px;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 0 0;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    width: $campaign-hero-icon-height * 2.5;

    &:hover {
      background-color: transparentize($clr_brandPrimary, 0.15);
      transform: scale(1.1) translate(-50%, -50%);
    }

    @include breakpoint(tablet) {
      height: $campaign-hero-icon-height-desktop * 1.75;
      width: $campaign-hero-icon-height-desktop * 2.5;
    }

    @include part(v2) {
      padding: 2rem 3rem;
      background-color: rgba(31, 168, 223, 90%);
      border-radius: 50px;

      @include breakpoint(tablet) {
        height: 63px;
        width: 92px;
      }
    }
  }

  @include part(play-icon) {
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: $campaign-hero-icon-height * 0.5 0 $campaign-hero-icon-height * 0.5 math.div($campaign-hero-icon-height, 1.2);
    border-color: transparent transparent transparent #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-44%, -50%);

    @include breakpoint(tablet) {
      border-width: $campaign-hero-icon-height-desktop * 0.5 0 $campaign-hero-icon-height-desktop * 0.5 math.div($campaign-hero-icon-height-desktop, 1.2);
    }
  }
}
