@import "base/helpers";

.box-placeholder {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-size: 0;
  line-height: 0;
  margin: 0 auto;
  max-width: 1147px;

  .box-placeholder__sticky-donate {
    display: none;
    width: 100%;
    min-width: 200px;
    min-height: 180px;
    flex: 1;
  }

  .text {
    display: inline-block;
    background-color: #444;
    height: 12px;
    border-radius: 100px;
    margin: 5px 0;
    min-width: 100px;
    opacity: 10%;
    animation: fading 1.5s infinite;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.link {
      background-color: var(--blue);
      opacity: 40%;
    }

    &.line {
      width: 100%;
    }

    &.category {
      width: 100px;
      margin-bottom: 10px;
    }
  }

  h4.text {
    height: 20px;
    margin: 3px 0;
    opacity: 20%;
  }

  .image {
    height: 200px;
    background-color: #444;
    opacity: 10%;
    animation: fading 1.5s infinite;
  }

  .information-sections {
    height: 100px;
    background-color: #444;
    opacity: 10%;
    animation: fading 1.5s infinite;
  }

  hr.no-border {
    margin: 20px;
    border: none;
  }

  hr {
    margin: 20px;
    border-color: #f3f3f3;
  }

  @include breakpoint(phablet) {
    display: flex;
    padding: 1rem;

    .box-placeholder__main-content {
      flex: 2;

      .hero-image-placeholder.image {
        height: 350px;
      }
    }

    .box-placeholder__sticky-donate {
      display: block;
      margin-left: 1rem;

      .sticky-donate-image {
        width: 100%;
        flex: 1;
      }
    }
  }
}

@keyframes fading {
  0% {
    opacity: 10%;
  }

  50% {
    opacity: 20%;
  }

  100% {
    opacity: 10%;
  }
}
