@import "../../base/helpers";

@include component(image) {
  transition: opacity 0.3s;

  // Options
  @include option(loaded) {
    opacity: 100%;
  }
}
