@import "base/helpers";
@import "base/legacy/campaign-v2-typography";

@include component(progress-meter) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_brandSecondary: #e444a0 !default;
  $clr_brandTertiary: #ffb202 !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $progress-meter-height: 15px !default;
  $progress-meter-mobile-height: 6px !default;
  $progress-meter-duration: 2.8s;

  // Parts ----------------------
  @include part(bar) {
    background-color: #ededed;
    border-radius: $progress-meter-height;
    height: $progress-meter-mobile-height;
    overflow: hidden;
    position: relative;

    @include breakpoint(tablet) {
      border-radius: $progress-meter-height;
      height: $progress-meter-height;
    }
  }

  @include part(fill) {
    background-color: $clr_brandPrimary;
    border-radius: $progress-meter-height;
    bottom: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: width $progress-meter-duration ease-out;
    width: 0%;
    z-index: 1;

    @include breakpoint(tablet) {
      border-radius: $progress-meter-height;
    }
  }

  @include part(bar) {
    background-color: #ededed;
  }

  @include part(label) {
    font-family: $font-family-primary;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;

    @include breakpoint(tablet) {
      display: none;
    }

    @include option(fill-raised) {
      position: absolute;
      top: 50%;
      left: $progress-meter-height*0.5;
      transform: translateY(-50%);
      color: #fff;
    }

    @include option(bar-raised) {
      color: #808080;
      position: absolute;
      top: 50%;
      left: $progress-meter-height*0.5;
      transform: translateY(-50%);
    }

    @include option(goal) {
      color: #808080;
      position: absolute;
      top: 50%;
      right: $progress-meter-height*0.5;
      transform: translateY(-50%);
      z-index: 1;
    }
  }

  @include part(values) {
    display: none;

    @include breakpoint(tablet) {
      display: block;
      margin-top: 0.5rem;
    }
  }

  @include part(value-raised) {
    @include typography-v2-size-1;
    @include typography-bold;

    color: $clr_brandPrimary;
  }
}
