@import "base/fonts";
@import "base/helpers";
@import "base/index";
@import "base/modal";
@import "base/forms/forms";
@import "base/legacy/bootstrap";
@import "base/legacy/structure";
@import "base/legacy/campaign-v2-typography";

@include component(modal-content) {
  @include typography-v2-base;

  box-shadow: none !important;
  border-radius: 0 !important;

  @include part(body) {
    @include breakpoint(phablet) {
      padding: 1em 2em 2em !important;
    }

    @include resetButtonSizes;

    h1 {
      font-family: Inter, sans-serif;

      @include breakpoint(tablet) {
        font-size: 34px;
        line-height: 1.6em;
        margin-bottom: 1rem;
      }
    }

    h4 {
      margin-bottom: 1rem;
    }

    h1,
    h4,
    p {
      text-align: center;
    }
  }
}
