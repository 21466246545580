@charset "UTF-8";

.padd-small {
  padding: 5px 10px !important;
}

* {
  -webkit-font-smoothing: antialiased;
}

a,
a:hover {
  text-decoration: none !important;
}

.upper {
  text-transform: uppercase !important;
}

.bold {
  font-weight: bold !important;
}

.text-shadow {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 40%);
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.text-medium {
  font-size: 18px !important;
}

.text-small {
  font-size: 11px !important;
}

.text-white {
  color: white;
}

.text-tiny {
  font-size: 9px !important;
}

.text-grey {
  color: #929292;
}

.text-dark {
  color: #666;
}

#inner-nav {
  padding: 10px 0 13px !important;
}

.desktop-hide {
  display: none;
}

html {
  overflow-x: hidden;
}

.round {
  border-radius: 7px !important;
  border-radius: 7px !important;
  border-radius: 7px !important;
  border-radius: 7px !important;
  overflow: hidden;
}

.round-show {
  border-radius: 7px !important;
  border-radius: 7px !important;
  border-radius: 7px !important;
  border-radius: 7px !important;
}

.animate {
  transition: all 0.1s linear;
  transition: all 0.1s linear;
  transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.border-white {
  border: 1px solid #fff;
}

.border-white-big {
  border: 4px solid #fff;
}

.shadow {
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 10%);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 10%);
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 10%);
}

.shadow-heading {
  box-shadow: inset 0 -8px 11px -9px rgba(0, 0, 0, 20%);
  box-shadow: inset 0 -8px 11px -9px rgba(0, 0, 0, 20%);
  box-shadow: inset 0 -8px 11px -9px rgba(0, 0, 0, 20%);
}

.innershadow {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 20%);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 20%);
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 20%);
}

.margin-top {
  margin-top: 20px !important;
}

.margin-btm {
  margin-bottom: 20px !important;
}

.margin-btm-small {
  margin-bottom: 10px;
}

.padd {
  padding: 20px;
}

.mobile-show {
  display: none !important;
}

#mobile-menu {
  font-size: 15px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;
  background-color: rgba(0, 0, 0, 30%);
  padding: 20px;
  z-index: 10000000000000;

  & .btn-block {
    text-align: left !important;
    float: left;
    padding: 15px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 4px;

    @extend .round;
  }
}

.full {
  width: 100% !important;
  float: left;
  box-sizing: border-box; /* Safari/Chrome, other WebKit */
  box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

@media (max-width: 1224px) {
  .extra-hide {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .tablet-hide {
    display: none !important;
  }
}

@media (max-width: 520px) {
  .mobile-show {
    display: block !important;
  }

  .tablet-hide,
  .mobile-hide {
    display: none !important;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="search"] {
  box-sizing: border-box;
  appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.button {
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 8px rgba(102, 175, 233, 60%);
}

.form-control::placeholder {
  color: #777;
  opacity: 100%;
}

.form-control:input-placeholder {
  color: #777;
}

.form-control::input-placeholder {
  color: #777;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eee;
  opacity: 100%;
}

textarea.form-control {
  height: auto;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  line-height: 34px;
  line-height: 1.42857143 \0;
}

input[type="date"].input-sm,
input[type="time"].input-sm,
input[type="datetime-local"].input-sm,
input[type="month"].input-sm {
  line-height: 30px;
}

input[type="date"].input-lg,
input[type="time"].input-lg,
input[type="datetime-local"].input-lg,
input[type="month"].input-lg {
  line-height: 46px;
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled],
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="checkbox"].disabled,
fieldset[disabled] input[type="radio"],
fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

.radio-inline.disabled,
.checkbox-inline.disabled,
fieldset[disabled] .radio-inline,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio.disabled label,
.checkbox.disabled label,
fieldset[disabled] .radio label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm,
.form-horizontal .form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}

.input-lg,
.form-horizontal .form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.input-lg + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%);
}

.has-error .form-control:focus {
  border-color: #843534;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 7.5%), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }

  .form-inline .input-group > .form-control {
    width: 100%;
  }

  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }

  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  top: 0;
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.3px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
  }
}

.clearfix::before,
.clearfix::after,
.form-horizontal .form-group::before,
.form-horizontal .form-group::after {
  content: " ";
  display: table;
}

.clearfix::after,
.form-horizontal .form-group::after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.affix {
  position: fixed;
  transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

body > .alert {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 300;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert > p,
.alert > ul {
  margin-bottom: 0;
}

.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

#loading {
  background-image: url("/images/overlay.png");
  background-repeat: repeat;
}

.flash-message {
  &.--error {
    top: 8rem;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 585px;
    text-align: center;
    z-index: 10;

    button {
      display: none;
    }
  }
}
