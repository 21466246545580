@import "base/helpers";

@include component(information-tab) {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 1rem;
  background-color: #f1f1f1;
  font-size: 1.5rem;
  margin: 5px 0;

  svg {
    width: 25px;
    height: 25px;
    margin: 0 5px 0 0;
  }

  // override common css
  p {
    @include typography-v2-size-4;

    margin: 0;
  }
}
