@import "base/helpers";

@include component(comment-item) {
  // Variables ----------------------
  $clr_textInverse: #fff !default;
  $clr_textMeta: #aeaeae !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;

  // Component ----------------------

  // Parts ----------------------
  @include part(comment) {
    align-items: top;
    display: flex;
    width: 100%;
  }

  @include part(comment-body) {
    flex: 1 1 auto;
    margin-left: 8px;
  }

  @include part(bubble) {
    background-color: #f1f1f1;
    border-radius: 16px;
    padding: 8px 16px;

    @include breakpoint(phablet) {
      border-radius: 24px;
      padding: 12px 24px;
    }
  }

  @include part(comment-copy) {
    font-family: $font-family-primary;
    font-size: 14px;
    line-height: 1.29;

    @include breakpoint(phablet) {
      font-size: 18px;
    }

    .placeholder {
      min-height: 30px;
      width: 100%;
      border-radius: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include part(text) {
        width: 100%;
        border-radius: 18px;
        height: 5px;
        margin-bottom: 5px;
        background-color: white;
      }
    }
  }

  @include part(comment-html) {
    display: inline;
    margin-top: 16px;

    // Overflow long text (e.g., links)
    word-wrap: break-word;

    @include typography-v2-size-4;

    // Scoped styles for HTML campaign updates
    img {
      max-width: 100% !important;
    }

    // ensure sizing is consistent for inner comment content
    * {
      @include typography-v2-size-4;

      line-height: normal;
    }

    // override comment header css for inner comment content
    h2 {
      margin-bottom: 0 !important;
    }
  }

  @include part(name) {
    display: inline;
    font-weight: 700;
    padding-right: 4px;

    @include typography-v2-size-4;
  }

  @include part(meta) {
    margin: 0;
    list-style: none;
    line-height: 3em;
    font-size: 1rem;
    padding: 0 0 0 26px;
  }

  @include part(meta-item) {
    color: $clr_textMeta;
    display: inline;
    font-family: $font-family-primary;
    font-size: 12px;

    @include option(action-link) {
      margin-top: -1px;

      @include breakpoint(phablet) {
        margin-top: -4px;
      }
    }

    @include breakpoint(phablet) {
      font-size: 16px;
    }

    &:not(:first-child) {
      &::before {
        content: "\00a0\00a0\2022\00a0\00a0"; // two spaces, bullet, two spaces
      }
    }
  }

  @include part(action-link) {
    &.button {
      color: $clr_text;
      padding: 0 0 0 14px;
      border: none;
      font-size: 1.2rem;
      font-weight: 600;

      &:not([disabled]):hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  @include part(pending-link) {
    color: $clr_brandPrimary;
  }

  // Pending styles
  @include part(pending-header) {
    background-color: $clr_brandPrimary;
    color: $clr_textInverse;
    font-family: $font-family-primary;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    padding: 16px;

    @include breakpoint(phablet) {
      font-size: 24px;
    }
  }

  @include part(pending-message) {
    font-family: $font-family-primary;
    font-size: 16px;
    line-height: 1.2;
    padding: 16px 0;

    @include breakpoint(phablet) {
      font-size: 20px;
    }
  }

  @include part(pending-footer) {
    font-family: $font-family-primary;
    font-size: 14px;
    font-style: italic;
    padding: 16px;

    @include breakpoint(phablet) {
      font-size: 18px;
    }
  }

  // Options ----------------------
  @include option(is-pending) {
    background-color: transparentize($clr_brandPrimary, 0.84);
    margin-bottom: 24px;

    @include part(body) {
      padding-left: 16px;
      padding-right: 16px;
    }

    @include part(bubble) {
      background-color: #fff;
    }
  }

  @include option(v2) {
    @include part(meta) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      list-style: none;
    }

    @include part(meta-item) {
      &:first-child {
        position: relative;

        &::before {
          content: " - ";
          position: absolute;
          right: -8px;
          top: 1px;
          line-height: 1rem;
        }
      }

      &:not(:first-child) {
        &::before {
          display: none;
        }
      }
    }
  }
}
