@import "base/helpers";

@include component(textareaautosize) {
  // Variables ----------------------

  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $inputs_fontSize: 1rem !default;
  $inputs_paddingVertical: 0.5rem !default;
  $inputs_paddingHorizontal: 1rem !default;

  // Component ----------------------

  position: relative;
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  appearance: none;
  overflow: hidden;

  // Parts ----------------------

  @include part(element) {
    width: 100%;
    min-height: 10rem;
    border: $inputs_borderWidth solid $inputs_borderColor;
    border-radius: $inputs_borderRadius;
    padding: $inputs_paddingVertical $inputs_paddingHorizontal;

    // Pseudos ----------------------
    &::input-placeholder {
      opacity: 100%;
      font-size: $inputs_fontSize;
      color: #c3c3c3;
    }

    // States ----------------------
    &:focus {
      outline: none;
      border-color: rgba(31, 167, 219, 62%);
    }

    // Options ----------------------

    @include option(small) {
      min-height: 5rem;
      font-size: 0.75rem;
    }

    @include option(isInvalid) {
      border-color: $inputs_errorColor;
    }
  }
}
