@import "base/helpers";

// override react-responsive-carousel component style
.carousel {
  .thumbs {
    display: flex;
    justify-content: center;
  }

  .carousel-status {
    @include typography-v2-base;

    top: 82%;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 35px;
    margin-right: 6px;
    padding: 1px 8px;

    @include breakpoint(phablet) {
      top: 93%;
      right: 4px;
      font-size: 14px;
    }

    @include breakpoint(desktop) {
      background: none;
    }
  }

  .slide {
    display: flex;
    justify-content: center;
    max-height: 75vh;

    img {
      max-height: 100%;
      object-fit: contain;
    }

    iframe {
      margin: 0;
      width: 100%;
    }

    .campaign-hero {
      margin: 0;

      .campaign-hero__image {
        border: none;
        border-radius: 0;
      }
    }
  }

  .thumb {
    border: none;
    background-color: rgba(0, 0, 0, 0.15);
    opacity: 100%;
    padding: 0;
    display: flex;
    justify-content: center;

    img {
      opacity: 50%;
      margin: 0;
      padding: 0;
      object-fit: contain;
    }

    &.selected {
      border: 2px solid #fff;

      img {
        opacity: 100%;
        background-color: none;
      }
    }
  }

  &.carousel-slider {
    .control-arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      padding: 10px;
      top: calc(50% - 14px);
      border-radius: 50%;
      width: 36px;
      background-color: rgba(0, 0, 0, 0.5);

      &.control-next {
        margin-right: 5px;
      }

      &.control-prev {
        margin-left: 5px;
      }
    }
  }
}

@include component(image-carousel) {
  min-height: 180px;

  @include breakpoint(phablet) {
    min-height: 360px;
  }

  @include part(initial-display) {
    display: flex;
    max-width: 1147px;
    justify-content: center;
    margin: 0 auto;
    padding: 2rem 1rem 0 0;

    img {
      width: 100%;
      border-radius: 6px;
    }
  }

  @include part(button) {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 5px;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 40px;
    font-weight: bold;
    padding: 5px 14px;

    @include breakpoint(desktop) {
      bottom: 18px;
    }
  }

  @include part(initial-hero) {
    flex: 2;
  }

  @include part(labels) {
    background-color: lightgray;
    border-radius: 20px;
    padding: 4px 9px;
    text-align: center;
    color: gray;
  }

  @include part(initial-additional-images) {
    flex: 1;
    position: relative;
    min-width: 280px;

    img {
      &:nth-of-type(2) {
        margin-top: 17px;
      }
    }

    @include breakpoint(desktop) {
      max-width: 362px;
    }

    @include option(colorBackground) {
      background-color: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin: 0 0 1rem;
    }
  }
}
