@import "base/helpers";

@include component(link-button-group) {
  // Variables ----------------------
  $spacing-small: -0.5rem;
  $spacing-large: 1.5rem;

  // Component ----------------------
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  // Parts ----------------------

  @include part(item) {
    display: inline-block;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @include option(spacing-small) {
    margin-bottom: $spacing-small;
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    @include part(item) {
      display: inline-block;
      margin-bottom: 0.5rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  @include option(spacing-large) {
    margin-bottom: $spacing-large;
    margin-left: -0.25rem;
    margin-right: -0.25rem;

    @include part(item) {
      display: inline-block;
      margin-bottom: 0.5rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
}
