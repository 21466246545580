@import "base/helpers";
@import "base/legacy/campaign-v2-typography";

@include component(campaign-status) {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  padding: 2rem 1rem;

  @include breakpoint(phablet) {
    border-radius: 10px;
  }

  svg {
    width: 40px;
    height: 40px;
    margin-right: 1.5rem;
  }

  @include part(link) {
    display: flex;
    align-items: center;
    color: #333;

    svg {
      width: 15px;
      height: 15px;
      margin-left: 5px;
    }
  }

  // options
  @include option(completed) {
    background-color: #e8f8f0;
    color: #25b669;
  }

  @include option(pending-approval) {
    background-color: #fff5de;
    color: #fdbb25;
  }

  @include option(paused) {
    background-color: #fff5de;
    color: #fdbb25;
  }

  @include option(draft) {
    background-color: $clr_backgroundLightBlue;
    color: #30a8df;
  }

  @include option(approved-notlaunched) {
    background-color: #fdecf5;
    color: #e444a0;
  }

  @include option(approved) {
    background-color: #fdecf5;
    color: #e444a0;
  }

  @include option(denied) {
    background-color: #fee5e5;
    color: #cb1515;
  }

  @include option(payment-issues) {
    background-color: #fff5de;
    color: #fdbb25;
  }

  @include option(archived) {
    background-color: #ebebeb;
    color: #6f6f6f;
  }

  @include option(paused) {
    background-color: #ebebeb;
    color: #6f6f6f;
  }
}
