@import "base/helpers";

@include component(leaderboard) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $timing_fast: 0.2s !default;
  $usePx: true;

  // Component ----------------------
  @include typography-v2-base;

  line-height: 1;
  clear: both;
  list-style-type: none;

  @include part(empty-message) {
    @include typography-base;
    @include typography-size-7($usePx);
  }

  @include part(header) {
    padding: 0;

    @include spacing-margin-small;
  }

  @include part(heading) {
    @include typography-bold;
    @include typography-size-6($usePx);
    @include typography-v2-base;

    border-bottom: 3px solid $clr_text;
    display: inline-block;
  }

  @include part(body) {
    padding-top: 8px;
  }

  @include part(list) {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @include part(list-item) {
    @include typography-v2-base;
  }

  @include part(item-elem) {
    align-items: center;
    color: $clr_text;
    display: flex;
    padding: 8px;

    @include breakpoint(tablet) {
      padding: 12px 16px;
    }
  }

  @include part(item-pos) {
    flex: 0 0 24px;
    font-size: 20px;

    @include breakpoint(tablet) {
      flex: 0 0 28px;
      font-size: 24px;
    }
  }

  @include part(item-avatar) {
    flex: 0 0 40px;
    margin-right: 8px;
    width: 40px;

    @include breakpoint(tablet) {
      flex: 0 0 50px;
      margin-right: 12px;
      width: 50px;
    }
  }

  @include part(item-body) {
    flex: 1 1 100%;
  }

  @include part(item-title) {
    @include typography-size-7($usePx);

    line-height: 1.1;
  }

  @include part(item-amount) {
    @include typography-size-7($usePx);
    @include typography-bold;

    flex: 0 0 64px;
    text-align: right;
  }

  @include part(item-meter) {
    $meter-height: 6px;
    $meter-height-desktop: 8px;

    background-color: #ebebeb;
    border-radius: $meter-height;
    height: $meter-height;
    margin-top: 4px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include breakpoint(tablet) {
      border-radius: $meter-height-desktop;
      height: $meter-height-desktop;
    }
  }

  @include part(item-meter-fill) {
    background-color: $clr_brandPrimary;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
  }

  @include part(action) {
    margin: 0 auto;
    margin-top: 16px;
  }

  // Options ----------------------
  @include option(is-linkable) {
    @include part(item-elem) {
      transition: background-color $timing_fast ease-in-out;

      &:hover,
      &:focus {
        background-color: lighten($clr_brandPrimary, 45%);
        color: $clr_text; // Override link colour being applied on text
      }
    }
  }

  @include option(v2) {
    @include spacing-margin-bottom-xxlarge;

    .leaderboard__heading {
      border-bottom: none;
    }

    .leaderboard__item-title {
      color: #333;
    }

    .leaderboard__item-elem {
      &:hover {
        background-color: #e8f6fc;
      }
    }

    .leaderboard__action {
      border-bottom-width: 1px;
      margin: 16px 0 0;

      button {
        margin-bottom: 0;
        padding: 0.8rem 2rem !important;
        height: min-content;
        border: 1px solid #333;
        color: #333;

        @include typography-v2-bold;
      }

      @include breakpoint(phablet) {
        max-width: fit-content;
      }

      @include option(v2) {
        button {
          width: 100%;

          @include breakpoint(phablet) {
            width: auto;
          }
        }
      }
    }
  }

  @include option(2-digit-pos) {
    @include part(item-pos) {
      flex: 0 0 28px;
      font-size: 20px;

      @include breakpoint(tablet) {
        flex: 0 0 36px;
        font-size: 24px;
      }
    }
  }

  @include option(3-digit-pos) {
    @include part(item-pos) {
      flex: 0 0 36px;
      font-size: 20px;

      @include breakpoint(tablet) {
        flex: 0 0 40px;
        font-size: 24px;
      }
    }
  }
}
