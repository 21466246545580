@import "base/helpers";

@include component(numbered-steps) {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include part(item) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1.5rem;

    @include breakpoint(phablet) {
      flex-direction: row;
      text-align: left;
      margin-bottom: 0;
      padding: 0.5rem 0;
    }

    &:last-of-type {
      margin-bottom: 0;

      .numbered-steps__number-connector {
        display: none;
      }
    }
  }

  @include part(item-number) {
    border-radius: 30px;
    background-color: #e8f6fc;
    color: #2daae0;
    font-weight: bold;
    padding: 10px 20px;
    font-size: 1.5rem;

    @include breakpoint(phablet) {
      margin-right: 2rem;
    }
  }

  @include part(item-text) {
    margin-top: 2rem;

    p {
      @include typography-size-6;
    }

    @include breakpoint(phablet) {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }

  @include part(item-container) {
    position: relative;
    z-index: 1;
  }

  @include part(number-connector) {
    border-left: 3px solid #2daae0;
    height: 85px;
    position: absolute;
    left: 1.5rem;
    top: 2.5rem;
    z-index: -1;
    display: none;

    @include breakpoint(phablet) {
      display: block;
    }
  }

  &.landscape {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .numbered-steps__item-number {
      padding: 1rem 1.5rem;

      &.complete {
        color: #e8f6fc;
        background: #2daae0;
      }

      &.current {
        background: #2daae0;
        color: #fff;
      }
    }

    .numbered-steps__number-connector {
      background: #2daae0;
      height: 2px;
      left: 50%;
      position: absolute;
      top: calc(50% - 2px);
      z-index: -1;
      width: 50%;
    }
  }
}
