@import "base/helpers";

@include component(modal-close) {
  $border_clr: #eee;

  @include part(content) {
    @include typography-v2-base;

    display: flex;
    justify-content: flex-end;
    padding: 15px;

    &-icon {
      display: flex;
      flex-direction: column;
      padding: 10px;

      span {
        text-decoration: uppercase;
        font-weight: bold;
      }
    }

    button {
      text-transform: uppercase;
      background: none !important;
    }

    @include breakpoint(phablet) {
      margin-top: 0;
    }
  }

  @include part(share-label) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    &:hover {
      color: lightgray;

      .modal-close__share-toggle-icon {
        &::before,
        &::after {
          background-color: lightgray;
        }
      }
    }

    .modal-close__share-toggle-icon {
      @include option(secondary) {
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }

  @include part(content-share) {
    @include typography-v2-base;
    @include typography-v2-size-6;

    font-size: 12px;

    @include option(close) {
      @include typography-v2-size-6;

      font-size: 12px;
    }

    @include option(secondary) {
      color: #fff;

      .modal-close__share-toggle-icon {
        color: #fff;
      }
    }

    @include option(small) {
      font-size: 0.5rem;
    }
  }

  @include part(share-toggle-icon) {
    display: block;
    height: 15px;
    position: relative;
    top: 4px;
    left: 30%;
    min-width: 41px;

    &::before,
    &::after {
      background-color: $clr_text;
      border-radius: 2px;
      content: "";
      display: block;
      height: 2px;
      position: absolute;
      width: 12px;
    }

    &::before {
      left: 2px;
      transform: rotate(45deg);
      top: 4px;
    }

    &::after {
      left: 2px;
      transform: rotate(-45deg);
      top: 4px;
    }

    @include option(small) {
      font-size: 0.5rem;
    }
  }

  @include part(button-group) {
    .link-button-group {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .link-button-group__item:first-child {
        margin: 0;
      }
    }
  }
}
