@import "base/helpers";
@import "~/node_modules/react-toggle/style";

$inputs_borderRadius: 4px !default;
$inputs_borderWidth: 1px !default;
$inputs_borderColor: #eee !default;
$inputs_color: #000 !default;
$inputs_fontSize: 1rem !default;
$inputs_paddingVertical: 0.5rem !default;
$inputs_paddingHorizontal: 1rem !default;
$inputs_highlightColor: rgba(31, 167, 219, 82%);

.Checkbox {
  padding: 0.75rem 0;
  margin-bottom: 0.5rem;

  @include part(info-helper) {
    // Increasing specificity to override styles
    &.info-helper {
      color: rgb(216, 216, 216);
      font-size: 0.875rem;
      height: 1.2rem;
      line-height: 1.2;
      padding: 0;
      text-transform: none;
      vertical-align: text-bottom;

      // Undo cascading styles
      span {
        padding: 0;
      }
    }
  }

  @include option(align-top) {
    label {
      align-items: flex-start;
    }
  }

  @include option(align-middle) {
    label {
      align-items: center;
    }
  }

  @include option(align-bottom) {
    label {
      align-items: flex-end;
    }
  }

  label {
    align-items: flex-start;
    display: flex;
  }

  span {
    display: block;
    flex: 1 1 100%;
    float: left;
    padding: 1px 10px;
    white-space: wrap;
    font-weight: 500;
  }

  @include option(toggle-only) {
    height: auto;
    margin: 0;
    padding: 0;

    span {
      @include visuallyHidden;
    }
  }

  @include option(label-small) {
    font-size: 0.875rem;
  }

  @include option(label-regular) {
    font-size: 1rem;
  }

  @include option(label-large) {
    font-size: 1.25rem;
  }

  @include option(spacing-none) {
    margin-bottom: 0;
  }

  @include option(no-transition) {
    .react-toggle,
    .react-toggle * {
      transition-duration: 0s;
    }
  }
}

// Component ----------------------
.react-toggle {
  float: left;
}

.react-toggle.react-toggle--checked .react-toggle-thumb {
  border-color: $inputs_highlightColor;
}

.react-toggle.react-toggle--checked .react-toggle-track {
  background-color: $inputs_highlightColor;
}

.react-toggle:active .react-toggle-thumb,
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none;
}

.react-toggle.react-toggle--checked:hover .react-toggle-track,
.react-toggle.react-toggle--checked:hover:not(.react-toggle--disabled).react-toggle-track {
  background-color: darken($inputs_highlightColor, 10%);
}
