@import "base/helpers";

@include component(campaign-embed) {
  $clr_brandPrimary: #1fa8df !default;
  $clr_text: #333 !default;
  $size_informationText: 14px;
  $border_clr: #eee;

  @include part(widget-container) {
    padding: 15px;
    display: flex;
    flex-direction: column;

    @include typography-v2-base;

    h3 {
      margin-bottom: 1rem;
    }

    p {
      font-size: $size_informationText;
      margin-bottom: 1rem;
    }

    textarea {
      width: 100%;
      height: 200px;
      margin-bottom: 20px;
    }

    .link-button {
      max-width: 200px;

      @include typography-v2-bold;
      @include typography-v2-base;
    }
  }

  @include part(widget-preview) {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    margin: 20px 0;
    border-top: 1px solid $border_clr;
  }

  @include part(widget-preview-iframe) {
    width: 100%;

    iframe {
      width: 100%;

      @include typography-v2-base;

      .campaign-card {
        &--iframe {
          margin: 0 auto;
        }
      }
    }
  }
}
