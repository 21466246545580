@import "base/helpers";

@include component(selling-points) {
  @include flexBoxContent(column);

  animation: fadeIn 0.5s;

  @include part(selling-point) {
    @include flexBoxContent(column);

    padding: 0.25rem 1rem 1rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;

    &::after {
      position: absolute;
      width: 80%;
      background: #e0e0e0;
      height: 1px;
      top: 100%;
      content: "";
      left: 10%;
    }

    &:last-of-type {
      border: none;

      &::after {
        background: transparent;
      }
    }

    p {
      text-align: center;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }

    .icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      margin: 1rem 0;
      height: 40px;

      &.send {
        height: 33px;
      }

      &.give {
        height: 35px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
