@import "base/helpers";
@import "base/form";
@import "components/LinkButton/style";

@include component(form) {
  &.signup {
    @include resetFontSize;

    font-size: 16px;

    label {
      text-transform: none;
      font-size: inherit;
    }

    .form-group {
      margin-bottom: 0;
    }

    button[type="submit"] {
      font-size: 1em;
    }

    input {
      font-size: 0.85em;
    }

    ::placeholder {
      font-size: 1em;
      font-weight: 500;
    }
  }
}
