@import "base/helpers";

.ReactModal__Content {
  max-width: 95vw !important;
  z-index: 250;

  @include breakpoint(desktop) {
    max-width: 900px !important;
  }
}

.modal-content__body {
  padding: 1em 0;
  border-top: 1px solid #dfdfdf;
  box-shadow: none;
  border-radius: 0;

  .link-button {
    margin: 0.5rem 1rem;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    font-weight: 600;
    min-width: 16%;
  }

  @include breakpoint(phablet) {
    padding: 2em;
  }

  @include breakpoint(tablet) {
    max-height: 85vh;
  }
}

.modal-content__full-width {
  flex-direction: column;
}

@include component(modal-content) {
  @include typography-v2-base;

  box-shadow: none;

  @include part(body) {
    @include typography-v2-base;

    margin: 0 !important;
    padding: 2rem;
    margin-bottom: 1.5rem;

    h2 {
      text-align: center;
      line-height: 1.2em;
      margin-bottom: 2.5rem;
    }

    p {
      margin: 1.4rem auto;
      font-size: 1em;
      line-height: 1.6em;
      max-width: 80%;
    }
  }

  @include part(action-buttons) {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;

    @include breakpoint(phablet) {
      flex-direction: row;
    }
  }
}
