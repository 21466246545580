@import "base/helpers";

@include component(view-more) {
  $buttonColor: #333;

  position: relative;

  @include option(isDisplay) {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 10rem;
      bottom: 8rem;
      left: 0;
      background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0%)), to(#fff));
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0%), #fff 100%);
    }
  }

  @include part(button) {
    margin-top: 20px;
    display: none;
    color: $buttonColor;
    padding: 0 1rem;

    @include typography-bold;

    button {
      border-bottom-width: 1px;
      width: 100%;
      border-color: $buttonColor !important;

      @include breakpoint(phablet) {
        max-width: fit-content;
        padding: 0.8rem 2rem !important;
      }
    }

    @include option(isDisplay) {
      display: block;
    }
  }
}
