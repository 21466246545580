@import "base/helpers";

@include component(perks-container) {
  $smallSpacing: 1rem;

  scroll-margin-top: 90px;

  @include part(header) {
    @include spacing-margin-bottom-xxlarge;
  }

  ul {
    @include flexBoxContent(row);

    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;

    li {
      @include spacing-margin-small;

      display: flex;
      flex: 1 1 100%;
      max-width: 100%;
      flex-wrap: wrap;
      align-self: stretch;
    }
  }

  @include breakpoint(tablet) {
    ul {
      li {
        flex: 1 1 50%;
        max-width: 50%;
      }
    }
  }
}
