/** normalise spacing of all text **/
h1,
h2,
h3,
h4,
p,
a {
  margin: 0;
  padding: 0;
}

h1 {
  @include typography-v2-size-1;
  @include typography-v2-extra-bold;

  letter-spacing: -0.5px;
}

h2 {
  @include typography-v2-size-2;
  @include typography-v2-bold;

  letter-spacing: -0.5px;

  @include breakpoint(phablet) {
    letter-spacing: 0;
  }

  @include option(large) {
    font-size: 3rem;

    @include breakpoint(phablet) {
      font-size: 3.6rem;
    }

    @include typography-v2-extra-bold;
  }
}

h3 {
  @include typography-v2-size-3;
  @include typography-v2-bold;
}

p {
  @include typography-v2-size-7;

  @include option(small) {
    @include typography-v2-size-6;
  }

  @include option(notification) {
    @include typography-v2-size-5;
  }

  @include option(regular) {
    @include typography-v2-regular;
  }

  @include option(bold) {
    @include typography-v2-bold;
  }

  @include option(extra-bold) {
    @include typography-v2-extra-bold;
  }
}

a {
  @include option(regular) {
    @include typography-v2-regular;
  }

  @include option(bold) {
    @include typography-v2-bold;
  }

  @include option(extra-bold) {
    @include typography-v2-extra-bold;
  }
}

.button {
  @include typography-v2-size-5;

  @include option(small) {
    @include typography-v2-size-6;
  }

  @include option(regular) {
    @include typography-v2-regular;
  }

  @include option(bold) {
    @include typography-v2-bold;
  }

  @include option(extra-bold) {
    @include typography-v2-extra-bold;
  }
}
