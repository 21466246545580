@import "base/helpers";

@include component(error-display) {
  // Variables ----------------------

  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $inputs_fontSize: 1rem !default;
  $inputs_paddingVertical: 0.5rem !default;
  $inputs_paddingHorizontal: 1rem !default;

  // Component ----------------------

  position: relative;

  // Parts ----------------------

  @include part(pop-up) {
    position: absolute;
    z-index: 10;
    top: -35px;
    left: -15px;
    background: rgba(255, 0, 0, 90%);
    color: white;
    font-size: 0.8em;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-top: 8px solid rgba(255, 0, 0, 90%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      bottom: -8px;
      left: 50%;
      margin-left: -10px;
    }
  }
}
