@import "base/helpers";

// Mixins
@mixin button-base-new() {
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $link-button-padding-horizontal: 32px !default;
  $link-button-padding-vertical: 12px !default;
  $link-button-font-size: 16px !default;
  $timing_fast: 0.2s !default;

  padding: $link-button-padding-vertical $link-button-padding-horizontal;
  background: transparent;
  border: $inputs_borderWidth solid $inputs_borderColor;
  border-radius: $inputs_borderRadius;
  color: $inputs_color;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family-primary;
  font-size: $link-button-font-size;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  transition: background-color $timing_fast ease-out, border $timing_fast ease-out, color $timing_fast ease-out;
  vertical-align: middle;

  &[disabled],
  &.--disabled {
    cursor: not-allowed;
    opacity: 60%;
    pointer-events: none;
  }
}

@include component(button) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;
  $inputs_borderRadius: 4px !default;
  $inputs_borderWidth: 1px !default;
  $inputs_borderColor: #eee !default;
  $inputs_color: #000 !default;
  $inputs_paddingVertical: 0.5rem !default;
  $inputs_paddingHorizontal: 1rem !default;
  $inputs_fontSize: 1rem !default;
  $button_secondaryBackground: rgba(31, 167, 219) !default;
  $button_primaryBackground: #e444a0 !default;
  $button_primaryColor: white !default;
  $button_selectedColor: rgba(31, 167, 219, 62%);
  $button_secondaryOutlineBackground: #fff !default;
  $button_secondaryOutlineBorder: $clr_brandPrimary !default;
  $button_secondaryOutlineColor: $clr_brandPrimary !default;
  $button_roundedRadius: 35px !important; // overwriting structure.css

  $timing_fast: 0.2s;

  // Component ----------------------

  padding: $inputs_paddingVertical $inputs_paddingHorizontal;
  background: transparent;
  border: $inputs_borderWidth solid $inputs_borderColor;
  border-bottom-color: darken($inputs_borderColor, 3%);
  border-bottom-width: 1px;
  color: $inputs_color;
  cursor: pointer;
  font-family: $font-family-primary;
  font-size: $inputs_fontSize;
  border-radius: $inputs_borderRadius;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  // Options ----------------------

  @include option(rounded) {
    border-radius: $button_roundedRadius;
    text-transform: none !important;
  }

  @include option(disabled) {
    opacity: 30%;
    cursor: not-allowed;
  }

  @include option(primary) {
    min-width: 50%;
    padding: $inputs_paddingVertical * 1.25 $inputs_paddingHorizontal * 1.25;
    color: $button_primaryColor;
    background-color: $button_primaryBackground;
    border-color: $button_primaryBackground;
    border-bottom-color: darken($button_primaryBackground, 20%);
    font-size: $inputs_fontSize;
    font-weight: bold;

    &.--rounded {
      border-radius: $button_roundedRadius;
      text-transform: none !important;
    }
  }

  @include option(secondary) {
    min-width: 50%;
    padding: $inputs_paddingVertical * 1.25 $inputs_paddingHorizontal * 1.25;
    color: $button_primaryColor;
    background-color: $button_secondaryBackground;
    border-color: $button_secondaryBackground;
    border-bottom-color: darken($button_secondaryBackground, 20%);
    font-size: $inputs_fontSize;
    font-weight: bold;

    &.--rounded {
      border-radius: $button_roundedRadius;
    }
  }

  @include option(small) {
    padding: $inputs_paddingVertical * 0.75 $inputs_paddingHorizontal * 0.75;
    font-size: $inputs_fontSize * 0.8;
    min-width: 60px;
  }

  @include option(tiny) {
    border-bottom-width: 1px;
    padding: 0.5rem;
    font-size: $inputs_fontSize * 0.75;
    min-width: 0;
  }

  @include option(subdued) {
    color: #9a9a9a;

    &:hover {
      color: $button_selectedColor;
      border-color: $button_selectedColor;
    }
  }

  @include option(subdued-hover) {
    background-color: #fff;
    color: #9a9a9a;
    font-weight: 700;
    transition: background-color $timing_fast ease-out, border $timing_fast ease-out, color $timing_fast ease-out;

    &:hover {
      background-color: $clr_brandPrimary;
      border-color: $clr_brandPrimary;
      color: #fff;
    }
  }

  @include option(icon) {
    min-width: 0;
    border: 0;
    padding: 0;
  }

  @include option(secondary-outline) {
    @include button-base-new;

    color: $button_secondaryOutlineColor;
    background-color: $button_secondaryOutlineBackground;
    border-color: $button_secondaryOutlineBorder;

    &:hover,
    &:focus {
      background-color: $button_secondaryOutlineBorder;
      color: #fff;
    }

    &.--rounded {
      border-radius: $button_roundedRadius;
      text-transform: none !important;
    }
  }

  @include option(selected) {
    background: $clr_brandPrimary;
    border-color: $clr_brandPrimary;
    color: white;
  }
}
