@import "base/helpers";

@include component(matched-giving) {
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  border-radius: 10px;
  width: 100%;
  padding: 0.5rem;

  @include breakpoint(phablet) {
    padding: 1rem 3rem 1rem 1rem;
    margin-bottom: 1rem;
  }

  @include part(info) {
    &-title {
      font-weight: bold;
    }
  }

  @include part(image) {
    position: relative;
    margin-right: 2rem;

    &-double {
      position: absolute;
      top: 20px;
      left: 24px;

      @include breakpoint(phablet) {
        left: 24px;
        top: 22px;
      }

      &.--noImage {
        position: relative;
        top: 0;
        left: 0;
      }
    }

    svg {
      width: 20px;
      height: 20px;

      @include breakpoint(phablet) {
        width: 28px;
        height: 28px;
      }
    }

    &.--noImage {
      margin-right: 1rem;
    }
  }

  .avatar {
    margin-top: 0;
  }
}
