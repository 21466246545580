@import "base/helpers";

@include component(comment-list) {
  // Variables ----------------------
  $avatar-size-small: 36px !default;
  $avatar-size-small-desktop: 56px !default;

  // Parts ----------------------
  @include part(item) {
    margin-bottom: 16px;

    &:last-of-type {
      @include spacing-margin-xlarge;
    }

    @include breakpoint(phablet) {
      margin-bottom: 24px;
    }
  }

  @include part(thread) {
    margin-left: calc(8px + #{$avatar-size-small});
    margin-top: 16px;

    @include breakpoint(phablet) {
      margin-left: calc(8px + #{$avatar-size-small-desktop});
      margin-top: 24px;
    }
  }
}
