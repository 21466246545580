@import "base/helpers";

@include component(info-helper) {
  // Component ----------------------

  position: relative;
  color: inherit;

  @include part(pulse) {
    width: 30px;
    height: 30px;
    border: rgba(255, 178, 0, 38%);
    border-radius: 30px;
    background-color: rgba(255, 178, 0, 74%);
    z-index: 1;
    left: -5px;
    top: -12px;
    position: absolute;
    animation: pulse 3s ease-out;
    animation-iteration-count: infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0%;
  }

  25% {
    transform: scale(0);
    opacity: 10%;
  }

  50% {
    transform: scale(0.1);
    opacity: 30%;
  }

  75% {
    transform: scale(0.5);
    opacity: 50%;
  }

  100% {
    transform: scale(1);
    opacity: 0%;
  }
}
