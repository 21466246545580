@import "base/helpers";

@include component(perk-card) {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-container {
    padding: 2rem 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    width: 100%;

    @include breakpoint(phablet) {
      padding: 0;
      border-radius: 10px;
      box-shadow: -1px 0 7px 0 rgba(181, 177, 181, 40%);
      box-shadow: -1px 0 7px 0 rgba(181, 177, 181, 40%);
      box-shadow: -1px 0 7px 0 rgba(181, 177, 181, 40%);
    }

    &:first-of-type {
      padding-top: 0;
    }
  }

  @include part(body-content) {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoint(phablet) {
      flex-direction: column;
    }
  }

  @include part(information-container) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @include part(info-container) {
    display: flex;
    flex-direction: column;
  }

  @include part(description) {
    @include typography-v2-size-4;
  }

  @include part(additional-info-container) {
    display: flex;
    justify-content: space-between;

    @include option(mobile) {
      background-color: #fafafa;
      border-radius: 5px;

      @include spacing-margin-xsmall;
      @include spacing-margin-top-xsmall;

      @include breakpoint(phablet) {
        margin-top: 0;
        margin-bottom: 0;
      }

      .perk-card__info-container {
        padding: 1rem;
      }
    }

    @include option(desktop) {
      @include spacing-margin-top-small;

      .perk-card__info-container {
        display: none;
      }
    }

    @include breakpoint(phablet) {
      margin: 0;

      @include option(mobile) {
        .perk-card__info-container {
          display: none;
        }
      }

      @include option(desktop) {
        .perk-card__info-container {
          display: block;
          padding: 0;
        }
      }
    }
  }

  @include part(title) {
    margin-top: 0;

    @include typography-v2-size-4;
    @include spacing-margin-xsmall;
  }

  @include part(image) {
    height: 110px;
    border-radius: 5px;

    @include breakpoint(phablet) {
      width: 100%;
      max-width: 100%;
      height: auto;
      border-radius: 5px 5px 0 0;
    }
  }

  @include part(information) {
    @include breakpoint(phablet) {
      padding: 2rem;
    }
  }

  @include part(additional-info) {
    font-size: 12px !important;
    line-height: 1.2;
    margin-bottom: 0;
  }

  @include part(button-container) {
    padding: 0 1rem 1rem;
    margin-top: auto;

    @include breakpoint(desktop) {
      padding: 0 2rem 2rem;
    }
  }

  @include part(donation-button) {
    width: 100%;
    margin-top: 1rem;
    font-weight: bold;
    padding: 0.6rem 2.5rem !important;
  }
}
