@import "base/helpers";
@import "base/index";
@import "base/legacy/bootstrap";
@import "base/legacy/structure";
@import "base/legacy/campaign-v2-typography";
@import "base/modal";
@import "components/Avatar/_style";
@import "components/Header/_style";
@import "components/CampaignCard/_style";
@import "components/Navigation/_style";
@import "components/Footer/_style";
@import "components/FlashMessage/_style";

html,
body {
  overflow-x: initial !important;
}

@include resetFontSize;

.box-placeholder {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-size: 0;
  line-height: 0;
  margin: 0 auto;
  max-width: 1147px;

  .box-placeholder__sticky-donate {
    display: none;
    width: 100%;
    min-width: 200px;
    min-height: 180px;
    flex: 1;
  }

  .text {
    display: inline-block;
    background-color: #444;
    height: 12px;
    border-radius: 100px;
    margin: 5px 0;
    min-width: 100px;
    opacity: 10%;
    animation: fading 1.5s infinite;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.link {
      background-color: var(--blue);
      opacity: 40%;
    }

    &.line {
      width: 100%;
    }

    &.category {
      width: 100px;
      margin-bottom: 10px;
    }
  }

  h4.text {
    height: 20px;
    margin: 3px 0;
    opacity: 20%;
  }

  .image {
    height: 200px;
    background-color: #444;
    opacity: 10%;
    animation: fading 1.5s infinite;
  }

  .information-sections {
    height: 100px;
    background-color: #444;
    opacity: 10%;
    animation: fading 1.5s infinite;
  }

  hr.no-border {
    margin: 20px;
    border: none;
  }

  hr {
    margin: 20px;
    border-color: #f3f3f3;
  }

  @include breakpoint(phablet) {
    display: flex;
    padding: 1rem;

    .box-placeholder__main-content {
      flex: 2;

      .hero-image-placeholder.image {
        height: 350px;
      }
    }

    .box-placeholder__sticky-donate {
      display: block;
      margin-left: 1rem;

      .sticky-donate-image {
        width: 100%;
        flex: 1;
      }
    }
  }
}

@keyframes fading {
  0% {
    opacity: 10%;
  }

  50% {
    opacity: 20%;
  }

  100% {
    opacity: 10%;
  }
}

@include component(campaign-container) {
  @include typography-v2-base;

  $extraSmallSpacing: 0.5rem;
  $largeSpacing: 1.5rem;
  $border_clr: #eee;
  $container_size: 1147px;
  $containerSpacing: 0 1rem;

  max-width: $container_size;
  display: flex;
  flex-direction: row;

  a,
  p,
  span,
  button,
  .link-button {
    @include typography-v2-base;

    text-transform: none !important;
  }

  @include part(matched-giving) {
    padding: $containerSpacing;

    @include spacing-margin-top-xsmall;
  }

  @include breakpoint(phablet) {
    margin: 2rem auto 0;
  }

  @include part(title) {
    padding: $containerSpacing;

    @include spacing-margin-top-regular;
    @include spacing-margin-large;

    @include option(marginTopNone) {
      @include breakpoint(desktop) {
        margin-top: 0;
      }
    }
  }

  @include part(pitch) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0;

    @include spacing-padding-top-regular;
    @include spacing-padding-bottom-regular;

    @include option(team) {
      border-top: 0;
      border-bottom: 0;
      padding-top: 0;
    }

    @include breakpoint(phablet) {
      padding-top: 0;

      @include option(team) {
        border-bottom: 1px solid #eee;

        @include spacing-margin-regular;
      }
    }

    .campaign-container__pitch-content-container {
      margin: $largeSpacing 0;
    }
  }

  @include part(pitch-owner) {
    width: 100%;
    display: flex;
    align-items: center;
    padding: $containerSpacing;

    &-avatar {
      display: flex;
      align-self: center;
    }

    .campaign-container__camp-owner-container {
      margin-left: 1rem;
      flex: 2;

      .campaign-container__camp-owner {
        font-weight: bold;
        margin: 0 0 0 0.6rem;

        @include option(fundraiser) {
          margin-left: 1px;

          @include typography-v2-regular;
        }

        @include option(team) {
          margin-left: 1px;

          @include typography-v2-regular;
        }

        &-additional-info {
          display: flex;
          flex-direction: column;
          margin-top: 0.75rem;

          @include breakpoint(phablet) {
            flex-direction: row;
          }

          &-text {
            display: flex;
            margin: 0 $extraSmallSpacing;
            align-items: center;
            margin-bottom: 6px;

            svg {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }

            p {
              margin-bottom: 0;
            }

            @include breakpoint(desktop) {
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }

  @include part(perk-container) {
    display: none;
    border-top: 1px solid $border_clr;
    padding: 2rem 1rem 0;

    @include spacing-margin-small;

    &.--display {
      display: block;

      li {
        border-bottom: 1px solid $border_clr;

        @include breakpoint(phablet) {
          border-bottom: none;
          padding-right: 1rem;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  @include part(fundraiser-signup) {
    display: flex;
    align-items: normal;
    flex-direction: column;
    width: 100%;

    @include spacing-margin-top-regular;
    @include spacing-margin-bottom-xxlarge;

    @include option(spacing) {
      border-top: 1px solid $border_clr;
      margin: 2.5rem 0;
    }

    &-title {
      flex: 2;
      text-align: center;
      align-self: flex-start;
      margin-bottom: 0;
      padding-right: 1rem;
    }

    a {
      width: 100%;
      max-height: 60px;
      padding: 0.8rem 2rem;
      margin-bottom: 0.5rem;
      flex: 1;
    }

    @include breakpoint(phablet) {
      flex-direction: row;
      align-items: flex-end;

      &-title {
        padding-bottom: 0;
      }
    }

    @include breakpoint(desktop) {
      flex-direction: row;

      &-title {
        text-align: left;
      }
    }

    &-button {
      font-weight: 700;

      a {
        width: 100%;
      }
    }

    @include option(emptyFundraiser) {
      margin-bottom: 0;

      h4 {
        @include spacing-margin-small;

        @include breakpoint(phablet) {
          margin-bottom: 0;
        }
      }

      @include spacing-padding-top-regular;
    }
  }

  @include part(content) {
    overflow: hidden;
    max-width: 100vw;
    flex-direction: column;
    flex: 2;
  }

  @include part(team-section) {
    max-width: $container_size;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    .team-title {
      padding: 1rem;
      margin: 0;
    }

    .team-member {
      display: flex;
      flex-direction: row;
      max-height: 60px;
      align-items: center;
      justify-content: flex-start;

      .team-member-image-wrapper {
        display: flex;
        flex-direction: row;
        margin: 0 !important;
        padding: 0;
        width: auto;

        .team-member-image {
          width: 30px;
          height: 30px;
        }
      }

      .team-member-name {
        padding: 1rem;
      }

      .team-member-contact-name {
        width: 30px;
        height: 30px;
      }
    }
  }

  @include part(share-button-container) {
    min-height: 35px;
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 1.2rem 1rem;

    span {
      flex: 0.5;
    }

    .campaign-container__share-values-container {
      display: flex;
      flex: 3;

      .campaign-container__share-values {
        @include flexBoxContent(column);

        justify-content: center;
        padding-right: 10px;

        span,
        .SocialMediaShareCount {
          font-weight: bold;
        }

        .campaign-container__share-values-subtitle {
          margin-top: -3px;
          color: gray;
        }
      }
    }

    // overriding base styles
    button {
      width: fit-content !important;
      padding: 0 4rem !important;
      margin: 0.7rem 0 !important;
      padding: 0.6rem 4rem !important;
    }
  }

  @include part(information) {
    max-width: 684px;

    h1 {
      text-align: left;
    }
  }

  @include part(progress-bar) {
    margin-top: $largeSpacing;

    .progress-meter {
      .progress-meter__fill {
        border-radius: 30px;
        background-color: #28ab60;
      }

      .progress-meter__value-raised {
        @include typography-v2-extra-bold;

        color: #28ab60;
        margin: 0 6px 0 0;

        @include breakpoint(phablet) {
          margin: 0;
        }
      }

      .progress-meter__value-target {
        color: #333;
        width: 100%;

        @include breakpoint(phablet) {
          padding-left: 0;
        }
      }

      .progress-meter__values {
        display: flex;
        align-items: baseline;
        margin-top: 0;

        @include spacing-margin-xsmall;

        @include breakpoint(phablet) {
          @include spacing-margin-regular;
        }

        @include breakpoint(phablet) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }

      .progress-meter__bar {
        border-radius: 30px;
        margin-bottom: $extraSmallSpacing;
        height: 6px;

        @include breakpoint(tablet) {
          height: 15px;
        }
      }

      .progress-meter__label {
        &.--fill-raised,
        &.--goal,
        &.--bar-raised {
          display: none;
        }
      }
    }
  }

  @include part(team-container) {
    max-width: $container_size;
    border-top: 1px solid $border_clr;
    margin: 0 auto;
    padding: $containerSpacing;

    .leaderboard {
      margin-bottom: 0;
    }
  }

  @include part(animated-total) {
    width: 100%;

    &:last-child {
      margin-bottom: 0.4rem;
    }
  }

  @include part(sticky-donate) {
    margin: 0 1rem;
    width: 280px;

    .well {
      box-shadow: -1px 0 7px 0 rgba(181, 177, 181, 40%);
      border-radius: 10px;
      padding: $extraSmallSpacing 1rem;
      margin-bottom: 2.5rem;
    }

    @include spacing-margin-xlarge;

    .campaign-container__countdown {
      text-align: left;
    }

    @include breakpoint(desktop) {
      width: 363px;
    }

    .link-button {
      width: 100%;
      margin-bottom: 1rem;
      display: none;

      @include typography-v2-base;
    }

    @include breakpoint(phablet) {
      .link-button {
        display: block;

        &.campaign-container__donate-button {
          @include typography-v2-size-3;
          @include typography-v2-bold;
        }
      }
    }

    @include option(mobile) {
      margin: 0;
      padding: $containerSpacing;
      width: 100%;
      box-shadow: none;

      @include spacing-margin-regular;

      @include breakpoint(phablet) {
        display: none;
      }

      .campaign-container__animated-total-numbers {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-bottom: 1rem;

        h2 {
          margin: 0;
        }

        p {
          margin: 0;
          padding-left: 1rem;
        }
      }
    }

    @include option(desktop) {
      position: sticky;
      position: sticky;
      top: 83px;
      display: none;
      transition: all 1s ease 0s;

      .well {
        box-shadow: -1px 0 7px 0 rgba(181, 177, 181, 40%);
      }

      @include breakpoint(phablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        .campaign-container__countdown {
          text-align: center;
        }
      }

      @include breakpoint(desktop) {
        top: 90px;
      }
    }
  }

  @include part(status) {
    flex: 1;
    margin: 0 auto $largeSpacing auto;
    border-radius: 5px;
    overflow-wrap: break-word;
    max-width: 280px;
  }

  @include part(status-container) {
    display: block;

    @include option(desktop) {
      display: none;
    }

    @include breakpoint(phablet) {
      &.--desktop {
        display: block;

        .campaign-container__status {
          max-width: 280px;
          margin: 0 auto 1.5rem;
        }
      }

      &.--mobile {
        display: none;
      }
    }

    @include breakpoint(desktop) {
      &.--desktop {
        .campaign-container__status {
          max-width: 363px;
        }
      }
    }

    .campaign-container__status {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }
  }

  @include part(sticky-bar-mobile) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    position: sticky;
    bottom: 0;
    border-top: 1px solid $border_clr;
    background: white;
    top: 80px;
    transition: all 1s ease 0s;
    z-index: 2;

    .campaign-container__share-button {
      flex: 1 !important;
      padding: 10px 23px;
      margin-right: 5px;
    }

    .campaign-container__donate-button {
      flex: 3 !important;
    }

    span {
      flex: 1;
      padding-right: 0.8rem;

      button {
        margin: 0;
      }
    }

    .link-button {
      flex: 1;

      @include typography-v2-base;
    }

    &.--doubleSize {
      .link-button {
        flex: 2;
        padding: 10px 23px;
      }
    }

    @include breakpoint(phablet) {
      display: none;
    }
  }

  @include part(countdown) {
    padding: 0;
    color: #333;
    margin-bottom: 6px;
  }

  @include part(modal-contact-title) {
    padding: 2rem 2rem 0;
  }

  @include part(comments-container) {
    min-height: 250px;
    display: inline-block;
    width: 100%;

    .comment-item__action-link {
      padding-top: 1px;
      border: none;
    }

    .comments {
      padding: $containerSpacing;
    }
  }

  @include part(supporters-container, updates-container) {
    border-top: 1px solid $border_clr;
    padding: $containerSpacing;

    &-title {
      @include spacing-margin-top-regular;
      @include spacing-margin-bottom-xxlarge;
    }

    &-total {
      padding-left: 1rem;
      font-weight: 400;
    }
  }

  @include part(team-members) {
    max-width: $container_size;
    padding: 0 1rem 1rem;
    text-align: left;
    margin: 0 auto;

    @include typography-v2-base;
    @include spacing-padding-top-regular;
    @include spacing-margin-top-xlarge;

    h2 {
      @include spacing-margin-bottom-xxlarge;

      margin-top: 0;
      text-align: left;
    }
  }

  @include part(team-members-item) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;

    @include breakpoint(phablet) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include part(story-container) {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .campaign-container__separator {
      margin: 0 auto;
    }

    iframe {
      width: 100%;
    }

    img {
      max-width: 100%;
    }

    p {
      @include typography-v2-size-4;
      @include spacing-margin-regular;
    }

    h2 {
      @include spacing-padding-large;
    }
  }

  @include part(separator) {
    border-bottom: 1px solid $border_clr;

    &.--top {
      border-top: 1px solid $border_clr;

      @include breakpoint(phablet) {
        border-top: none;
      }
    }

    &.--team {
      border-top: none;
    }

    &.--top-only {
      border-top: 1px solid $border_clr;
      border-bottom: none;
    }
  }

  @include part(fundraiser-signup-title) {
    text-align: left !important;
    padding-bottom: 0;

    @include typography-v2-bold;
  }

  .view-more-container {
    padding: 3rem 1rem;

    @include part(supporters) {
      padding-top: 0;
      margin-top: -2.4rem;

      @include spacing-padding-xlarge;
    }
  }

  .report-container {
    padding: 3rem 1rem;
    border-top: 1px solid $border_clr;
    width: 100%;

    .campaign-report-icon {
      margin-top: 0.3rem;
    }
  }
}
