@import "base/helpers";

@include component(modal-content) {
  // Variables ----------------------
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $inputs_errorColor: red !default;

  // Component ----------------------
  font-family: $font-family-primary;

  // Parts ----------------------
  @include part(header) {
    margin-bottom: 1rem;

    @include breakpoint(tablet) {
      margin-bottom: 1.5rem;
    }
  }

  @include part(full-width) {
    box-shadow: none;
    display: flex;
    justify-content: center;

    @include breakpoint(tablet) {
      max-height: 85vh;
      overflow-y: scroll;
    }
  }

  @include part(body) {
    &--list-item {
      display: flex;
    }

    &--information {
      font-weight: bold;

      &--spacing {
        margin: 0 2px;
      }
    }

    &--left-align {
      text-align: left;
    }
  }

  @include part(heading) {
    @include typography-size-5;
    @include typography-bold;

    border-bottom: 2px solid #979797;
    padding-bottom: 0.25rem;
  }

  @include part(error-message) {
    color: $inputs_errorColor;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  @include part(action-text) {
    @include typography-bold;

    font-size: 1rem;
    margin-bottom: 16px;
  }

  @include part(action-buttons) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    @include breakpoint(phablet) {
      flex-direction: row;
    }
  }
}
