.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: none;
  stroke: #30a8df;
  stroke-width: 4;
  stroke-miterlimit: 10;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #30a8df;
  stroke: #30a8df;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
