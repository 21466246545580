@mixin spacing-margin-xsmall() {
  margin-bottom: 1.2rem;
}

@mixin spacing-margin-top-xsmall() {
  margin-top: 1.2rem;
}

@mixin spacing-margin-small() {
  margin-bottom: 1.4rem;

  @include option(top-bottom) {
    margin: 1.4rem 0;
  }

  @include breakpoint(phablet) {
    margin-bottom: 1.8rem;

    @include option(top-bottom) {
      margin: 1.8rem 0;
    }
  }
}

@mixin spacing-margin-top-small() {
  margin-top: 1.8rem;
}

@mixin spacing-margin-regular() {
  margin-bottom: 2rem;

  @include option(top-bottom) {
    margin: 2rem 0;
  }

  @include breakpoint(phablet) {
    margin-bottom: 2.4rem;

    @include option(top-bottom) {
      margin: 2.4rem 0;
    }
  }
}

@mixin spacing-margin-top-regular() {
  margin-top: 2rem;

  @include breakpoint(phablet) {
    margin-top: 2.4rem;
  }
}

@mixin spacing-margin-large() {
  margin-bottom: 2.4rem;

  @include breakpoint(phablet) {
    margin-bottom: 3rem;
  }
}

@mixin spacing-margin-top-xlarge() {
  margin-top: 3rem;
}

@mixin spacing-margin-xlarge() {
  margin-bottom: 3rem;

  @include breakpoint(phablet) {
    margin-bottom: 3.6rem;
  }
}

@mixin spacing-margin-bottom-xxlarge() {
  margin-bottom: 2.6rem;

  @include option(page-title) {
    margin-bottom: 1rem;
  }

  @include breakpoint(phablet) {
    margin-bottom: 4.2rem;

    @include option(page-title) {
      margin-bottom: 4.2rem;
    }
  }
}

@mixin spacing-margin-top-xxlarge() {
  margin-top: 3.8rem;

  @include breakpoint(phablet) {
    margin-top: 4.2rem;
  }
}

@mixin spacing-padding-top-xsmall() {
  padding-top: 1.2rem;
}

@mixin spacing-padding-small() {
  padding-bottom: 1.8rem;

  @include option(top) {
    padding: 1.8rem 0;
  }
}

@mixin spacing-padding-top-regular() {
  padding-top: 2rem;

  @include breakpoint(phablet) {
    padding-top: 2.4rem;
  }
}

@mixin spacing-padding-bottom-regular() {
  padding-bottom: 2rem;

  @include breakpoint(phablet) {
    padding-bottom: 2.4rem;
  }
}

@mixin spacing-padding-large() {
  padding-bottom: 3rem;
}

@mixin spacing-padding-xlarge() {
  padding-bottom: 3rem;

  @include option(top) {
    padding: 3rem 0;
  }

  @include breakpoint(phablet) {
    padding-bottom: 3.6rem;

    @include option(top) {
      padding: 3.6rem 0;
    }
  }
}

@mixin spacing-surrounded-padding-small() {
  padding: 1.5rem;
}

@mixin spacing-surrounded-padding-regular-top-bottom() {
  padding: 2.2rem 0;
}

@mixin spacing-surrounded-padding-regular() {
  padding: 2.2rem 1.2rem;
}

@mixin spacing-surrounded-padding-large() {
  padding: 3.5rem 1.5rem;
}
