@use "sass:math";
@import "base/helpers";
@import "../../../../../../components/Timeline/style";
@import "../../../../../../components/TimelineStory/style";

.campaign-container__updates-container-title {
  margin-bottom: 0;
  padding-bottom: 0;
}

@include component(timeline) {
  @include part(list) {
    margin-bottom: -1.2rem;

    &::before {
      left: 50%;
    }

    @include breakpoint(tablet) {
      &::before {
        left: 1.5rem;
      }
    }
  }

  @include part(start) {
    &::before {
      margin-left: auto;
    }

    &::after {
      display: none;
    }

    @include breakpoint(tablet) {
      &::before {
        margin-left: 0.8rem;
      }
    }
  }

  @include part(end) {
    min-height: 3rem;

    &::before {
      margin: 1px calc(50% - 1.5px) auto;
      height: 2rem;
    }

    &::after {
      border: 3px solid #ccc;
      border-radius: 50%;
      box-sizing: border-box;
      content: "";
      display: block;
      height: 1.25rem;
      width: 1.25rem;
      margin: 1rem auto 0;
      bottom: -1.25rem;
    }

    @include breakpoint(tablet) {
      &::before {
        margin: -1px 0;
        position: absolute;
        left: 2.5rem;
        transform: translateX(-50%);
      }

      &::after {
        position: absolute;
        left: 1.85rem;
      }
    }
  }

  @include component(timeline__item) {
    width: 100%;
    padding: 1rem;

    @include breakpoint(tablet) {
      padding: 2rem 0 2rem 5rem;
    }
  }

  @include component(timeline-story) {
    padding-left: 3rem;
    font-family: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;

    @include part(inner) {
      font-size: 16px;
      max-width: 580px;
      text-align: center;

      @include breakpoint(tablet) {
        text-align: left;
      }
    }

    @include part(heading) {
      font-family: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 2em;
    }

    @include part(content) {
      font-family: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif;
      font-size: 1em;
      margin-top: 1rem;
    }

    @include part(date) {
      font-size: 0.75em;
    }

    @include part(icon) {
      left: -0.7rem;
      padding: 0.35em;
      background-color: #ffb202;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 1.75em;
      height: 1.75em;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      width: 1.75em;

      @include breakpoint(tablet) {
        font-size: 1.75em;
        height: 1.5em;
        position: absolute;
        width: 1.5em;
        left: -0.7rem;
      }
    }
  }
}
