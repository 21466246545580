@import "base/helpers";

$timeline-line-color: #ccc;
$timeline-line-width: 3px;
$margin-vertical: 1rem;

@mixin timeline-knob() {
  border: $timeline-line-width solid $timeline-line-color;
  border-radius: 50%;
  box-sizing: border-box;
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 1.25rem;
  width: 1.25rem;

  @include breakpoint(tablet) {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@mixin timeline-arrow-down() {
  border-left: $timeline-line-width solid $timeline-line-color;
  border-bottom: $timeline-line-width solid $timeline-line-color;
  box-sizing: border-box;
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 1rem;
  width: 1rem;
  transform: translateY(-100%) rotate(-45deg);

  @include breakpoint(tablet) {
    height: 1.125rem;
    width: 1.125rem;
  }
}

@mixin timeline-line-base() {
  background-color: $timeline-line-color;
  border-radius: $timeline-line-width;
  content: "";
  display: block;
  width: $timeline-line-width;
  z-index: 0;
}

@mixin timeline-line() {
  @include timeline-line-base;

  height: calc(4rem);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -1*$timeline-line-width;
  margin-top: -1*$timeline-line-width;
}

@include component(timeline) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;

  // Component ----------------------
  text-align: center;

  // Parts ----------------------
  @include part(label) {
    @include typography-bold;

    font-family: $font-family-primary;
    font-size: 1.125rem;

    @include option(intro) {
      margin-bottom: 0.75rem;
      margin-top: 1.5rem;

      @include breakpoint(tablet) {
        margin-top: 3rem;
      }
    }

    @include option(outro) {
      margin-top: 0.75rem;
    }
  }

  @include part(start) {
    text-align: center;

    &::before {
      @include timeline-knob;
    }

    &::after {
      @include timeline-line;
    }
  }

  @include part(end) {
    text-align: center;

    &::before {
      @include timeline-line;
    }

    &::after {
      @include timeline-arrow-down;
    }
  }

  @include part(list) {
    position: relative;
    margin-top: -$margin-vertical;
    margin-bottom: -$margin-vertical;

    &::before {
      @include timeline-line-base;

      height: 100%;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  @include part(item) {
    margin: $margin-vertical auto;

    @include breakpoint(tablet) {
      width: 50%;
    }
  }
}
