@import "base/helpers";

@include component(shareBar) {
  @include resetButtonSizes;

  // component
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 12px 0;
  list-style: none;

  @include typography-v2-base;

  // parts
  @include part(heading) {
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 2.5rem;
  }

  @include part(items) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    padding: 0;
    list-style: none;

    @include breakpoint(phablet) {
      flex-direction: row;
    }

    &--full-width {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      margin: 0;
    }
  }

  @include part(item) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0.25em;
    transition: all 0.25s ease-out;
    cursor: pointer;
    width: 100%;

    @include breakpoint(phablet) {
      margin: 0 0.25em;
      width: auto;
    }

    &:hover {
      transform: scale(1.05);
    }

    .shareBar__button {
      padding: 0.5rem;
    }

    &--full-width {
      width: 100%;
      padding: 0 10px 5px;
      margin: 0.5rem 0;

      @include breakpoint(phablet) {
        width: auto;
        min-width: 300px;
      }

      .shareBar__button {
        width: 100%;
        font-weight: bold;
      }

      .link-button {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: bold;

        .shareBar__shareText {
          padding: 0;

          .fa-code {
            padding-right: 10px;
            font-weight: bold;
          }
        }

        &.\--plain {
          max-width: 100%;
          margin: 0;

          .shareBar__shareText {
            line-height: 1em;
          }
        }
      }
    }
  }

  @include part(share-count) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    @include breakpoint(mobile) {
      margin-bottom: 14px;
    }

    .SocialMediaShareCount {
      font-size: 18px;
      letter-spacing: -1px !important;
      font-weight: 900 !important;

      @include typography-v2-base;

      @include breakpoint(desktop) {
        font-size: 38px;
      }
    }

    p {
      margin: 0;
      color: #9b9b9b;
      font-size: 16px;
    }
  }

  .shareBar__share-count {
    margin-bottom: 14px;
  }

  @include part(button) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    padding: 0;
    border-radius: 30px;
    border-style: none;
    overflow: hidden;
    cursor: pointer;

    @include breakpoint(mobile) {
      &.has-text {
        min-width: 150px;
        padding: 0.25em 1.25em;
        font-weight: 700;
      }
    }

    @include option(facebook) {
      background: #3b5998;
    }

    @include option(messenger) {
      background: #2196f3;
    }

    @include option(twitter) {
      background: #00aced;
    }

    @include option(email) {
      background: #7f7f7f;
    }

    @include option(whatsapp) {
      background: #25d366;
    }

    @include option(copy-url) {
      background-color: #e444a0;
      color: #fff;
      padding: 0.25em;

      svg {
        fill: #e444a0;
      }
    }
  }

  @include part(shareText) {
    color: white;
    padding: 0 10px 0 0;

    @include option(embedHtml) {
      display: flex;
      flex-direction: row;
      color: black;
    }
  }
}
