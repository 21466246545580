@import "base/helpers";

@include component(comment-modal) {
  // Variables ----------------------
  $clr_brandPrimary: #1fa8df !default;
  $clr_textInverse: #fff !default;
  $clr_textMeta: #aeaeae !default;
  $font-family-primary: "Source Sans Pro", "Helvetica Neue", helvetica, arial, sans-serif !default;
  $inputs_errorColor: red !default;

  // Component ----------------------

  // Parts ----------------------
  @include part(content) {
    font-family: $font-family-primary;
  }

  @include part(preview-intro) {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  @include part(body) {
    padding: 1em 2em 2em !important;
  }

  @include part(campaigner-name) {
    @include typography-bold;

    text-transform: uppercase;
  }

  @include part(preview-comment) {
    background-color: #f2f2f2;
    font-size: 16px;
    font-style: italic;
    margin: 16px 0;
    padding: 16px 24px;

    @include breakpoint(tablet) {
      font-size: 18px;
      margin: 24px 0;
      padding: 24px 32px;
    }
  }

  @include part(header) {
    margin-bottom: 16px;

    @include breakpoint(tablet) {
      margin-bottom: 24px;
    }
  }

  @include part(heading) {
    @include typography-bold;

    font-size: 4rem;
    text-align: center;

    @include breakpoint(tablet) {
      font-size: 3rem;
    }
  }

  @include part(footer) {
    margin-top: 16px;

    @include breakpoint(tablet) {
      margin-top: 24px;
    }
  }

  @include part(footer-action) {
    font-style: italic;
  }

  @include part(footer-link) {
    background: none;
    border: 0;
    color: $clr_brandPrimary;
    padding: 0;

    &:not(:disabled):hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @include part(form) {
    margin: 16px auto;
  }

  @include part(form-row) {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    min-width: 60%;

    &:last-child {
      margin-bottom: 0;
    }

    // Undo inherited styles
    label {
      display: flex;
      max-width: 100%;
      margin-bottom: 0;
      flex-direction: column;
      flex: 1;
      font-weight: 700;
      font-size: 1.6rem;
    }

    // Override rem values to pixels
    input {
      font-size: 16px;
      padding: 12px 16px;

      &::placeholder {
        font-size: 16px;
      }
    }
  }

  @include part(form-button) {
    margin: 1rem auto;
    display: flex;
    justify-content: center;
    max-width: inherit;
    width: auto;
    flex: 1 1 auto;
  }

  @include part(form-label) {
    display: inline-block;
    margin-bottom: 8px;
  }

  @include part(error-message) {
    color: $inputs_errorColor;
    font-size: 16px;
    margin-bottom: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  @include part(action-text) {
    @include typography-bold;

    font-size: 2rem;
    margin-bottom: 1rem;
    line-height: 1.6em;

    @include breakpoint(tablet) {
      font-size: 2.2rem;
    }
  }

  @include part(action-buttons) {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  // Options ----------------------
}
