@import "base/helpers";

.img-gallery-thumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @include breakpoint(tablet) {
    justify-content: flex-start;
  }

  .img-gallery-thumb {
    margin: 0 10px 10px 0;
    cursor: pointer;
    opacity: 0.7;
    border: none;
    background-color: transparent;
    transition: opacity 0.5s ease-out;
    border-radius: 6px;

    img {
      width: auto;
      height: 150px;
      object-fit: cover;
    }
  }

  .img-gallery-thumb.active,
  .img-gallery-thumb:hover {
    opacity: 1;
  }
}
